import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Container, Row, Col, Alert, Form } from 'react-bootstrap';
import CustomNavbar from './CustomNavbar'; // Import the navbar component
import { FaUser, FaEnvelope, FaPhone, FaUserTag } from 'react-icons/fa'; // Import icons

const Admin = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    // Fetch users when the component mounts
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem('userToken'); // Assuming the token is stored in localStorage
        const response = await axios.post('https://tasacionesya.com:8099/api/get_users_admin', {}, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.length === 0) {
          setError('No users found'); // Show error if no users are returned
        }

        const usersData = response.data.map(user => ({
          ...user,
          username: fixNameFormat(user.username),
          userLname: fixNameFormat(user.userLname),
        }));

        // Sort users: contacted ones show last
        const sortedUsers = usersData.sort((a, b) => (a.contacted === b.contacted ? 0 : a.contacted ? 1 : -1));

        setUsers(sortedUsers); // Store users in state
        setFilteredUsers(sortedUsers); // Set initial filtered users
      } catch (err) {
        setError('Error fetching users. Please try again.');
        console.error('Error fetching users:', err);
      }
    };

    fetchUsers();
  }, []);

  const fixNameFormat = (name) => {
    // Add a space if no space exists between lowercase and uppercase
    return name.replace(/([a-z])([A-Z])/g, '$1 $2');
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);

    const filtered = users.filter(user => {
      // Concatenate username and userLname to perform search on full name
      const fullName = `${user.username} ${user.userLname}`.toLowerCase();

      return (
        fullName.includes(searchValue) || // Search by full name
        user.email.toLowerCase().includes(searchValue) // Search by email
      );
    });

    setFilteredUsers(filtered);
  };

  const toggleContacted = async (index) => {
    // Update the contacted status of the user
    const updatedUsers = [...users];
    const updatedUser = { ...updatedUsers[index] };

    // Manually toggle contacted status
    updatedUser.contacted = !updatedUser.contacted;

    // Update state immediately for UI feedback
    updatedUsers[index] = updatedUser;
    setUsers(updatedUsers);
    setFilteredUsers(updatedUsers);

    // Send the updated status to the server
    try {
      await axios.post('https://tasacionesya.com:8099/api/update_contacted_status', {
        userId: updatedUser._id,
        contacted: updatedUser.contacted,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      });
    } catch (error) {
      console.error('Error updating contacted status:', error);
      setError('Failed to update contacted status');
    }
  };

  const handlePhoneClick = (phone, index) => {
    // Mark user as contacted
    const updatedUsers = [...users];
    const updatedUser = { ...updatedUsers[index] };
    updatedUser.contacted = true; // Update contacted status to true

    updatedUsers[index] = updatedUser;
    setUsers(updatedUsers);
    setFilteredUsers(updatedUsers);

    // Open WhatsApp in a new tab
    const whatsappMessage = "Hola. Mi nombre es Adriana, martillera de la plataforma Tasacionesya.com. Veo que se registró en la plataforma y me gustaría consultarle si pudo obtener su valuación. De lo contrario, puedo hacer la valuación y enviársela manualmente. Gracias.";

  // Open WhatsApp in a new tab with the default message
    window.open(`https://wa.me/${phone}?text=${encodeURIComponent(whatsappMessage)}`, '_blank');


    // Optionally, send the updated status to the server
    try {
      axios.post('https://tasacionesya.com:8099/api/update_contacted_status', {
        userId: updatedUser._id,
        contacted: updatedUser.contacted,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      });
    } catch (error) {
      console.error('Error updating contacted status:', error);
      setError('Failed to update contacted status');
    }
  };

  // Calculate counts
  const totalUsers = users.length;
  const contactedUsersCount = users.filter(user => user.contacted).length;
  const notContactedUsersCount = totalUsers - contactedUsersCount;

  // Inline styles
  const userCountBoxStyle = {
    backgroundColor: '#f8f9fa', // Light gray background
    border: '1px solid #6c757d', // Gray border
    borderRadius: '8px', // Rounded corners
    padding: '20px', // Spacing inside the box
    margin: '10px', // Spacing around the box
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow
    transition: 'transform 0.3s', // Smooth scaling
  };

  const userCountTitleStyle = {
    fontWeight: 'bold', // Bold title
    color: '#495057', // Dark gray color for the title
  };

  const userCountValueStyle = {
    fontSize: '1.5rem', // Larger font size for the value
    color: '#2EBF91', // Primary color for the count value
  };

  return (
    <>
      <CustomNavbar isAuthenticated={true} usertype="Admin" />

      <Container className="mt-5">
        <h1 className="text-center mb-4">Usuarios registrados</h1>

        {/* User counts display */}
        <Row className="text-center mb-4">
          <Col xs={12} md={4}>
            <div style={userCountBoxStyle} className="user-count-box">
              <p style={userCountTitleStyle}>Total de usuarios:</p>
              <p style={userCountValueStyle}>{totalUsers}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div style={userCountBoxStyle} className="user-count-box">
              <p style={userCountTitleStyle}>Usuarios contactados:</p>
              <p style={userCountValueStyle}>{contactedUsersCount}</p>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div style={userCountBoxStyle} className="user-count-box">
              <p style={userCountTitleStyle}>Usuarios no contactados:</p>
              <p style={userCountValueStyle}>{notContactedUsersCount}</p>
            </div>
          </Col>
        </Row>

        <Form className="mb-4">
          <Form.Control
            type="text"
            placeholder="Buscar por nombre..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </Form>

        {error && <Alert variant="danger">{error}</Alert>}

        <Row>
          {filteredUsers.length > 0 ? (
            filteredUsers.map((user, index) => (
              <Col key={index} md={4} className="mb-4">
                <Card className="shadow-sm"> {/* Add shadow */}
                  <Card.Body>
                    <Card.Title className="text-primary">
                      <FaUser className="me-2" />{user.username} {user.userLname}
                    </Card.Title>
                    <Card.Text>
                      <FaEnvelope className="me-2 text-secondary" />{user.email}
                    </Card.Text>
                    <Card.Text>
                      <FaPhone className="me-2 text-secondary" />
                      <a 
                        href={`#`} 
                        onClick={() => handlePhoneClick(user.phone, index)} 
                        style={{ cursor: 'pointer' }}>
                        {user.phone}
                      </a>
                    </Card.Text>
                    <Card.Text>
                      <FaUserTag className="me-2 text-secondary" />{user.user}
                    </Card.Text>
                    
                    <Form.Check 
                      type="switch"
                      id={`contacted-switch-${index}`}
                      label={user.contacted ? 'Contactado' : 'Sin contactar'}
                      checked={user.contacted}
                      onChange={() => toggleContacted(index)} // Allow manual toggling
                    />
                  </Card.Body>
                </Card>
              </Col>
            ))
          ) : (
            !error && <p>No users found</p>
          )}
        </Row>
      </Container>
    </>
  );
};

export default Admin;
